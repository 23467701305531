import {Link} from "gatsby"
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({siteTitle, modifier}) => {
    const logoData = useStaticQuery(graphql`
        query logoQuery {
          file(relativePath: {eq: "logo_480_white.png"}) {
            childImageSharp {
              fluid(maxWidth: 200) {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
        `);

    const navData = [
        {
            to: "/",
            name: "Home",
        },
        {
            to: "/#orchestra",
            name: "Orchester",
        },
        {
            to: "/#projects",
            name: "Projekte",
        },
        {
            to: "/#press",
            name: "Presse",
        },
        {
            to: "/#support",
            name: "Unterstützen",
        },
        {
            to: "/#contact",
            name: "Kontakt",
        },
    ]

    const showMobileMenu = (evt) => {
        let hamburger = evt.target.closest('.header--hamburger');
        if (!hamburger) {
            hamburger = document.querySelector('.header--hamburger');
        }
        hamburger.classList.toggle('header--hamburger__active');
        const mobileNavList = hamburger.parentElement.querySelector('.header--mobileNav--list');
        mobileNavList.classList.toggle('header--mobileNav--list__invisible');
        mobileNavList.classList.toggle('header--mobileNav--list__visible');
        document.querySelector('body').classList.toggle('menuOpen');
    }

    return (
        <header className={`header header--invisible header--${modifier} site--${siteTitle}`}>
            <div className="header--content">
                <Link to="/">
                    <Img className="header--logo" fluid={logoData.file.childImageSharp.fluid}/>
                </Link>
                <nav className="header--navbar">
                    {navData.map((x) => (
                        <Link
                            to={x.to}
                            key={x.name}
                            className="header--navlink">
                            {x.name}
                        </Link>
                    ))}
                </nav>
                <nav className="header--mobileNav">
                    <div className="header--hamburger" role="button" tabIndex={0} onClick={(evt) => showMobileMenu(evt)} onKeyDown={(evt) => showMobileMenu(evt)}>
                        <div className="header--hamburger--bar1"/>
                        <div className="header--hamburger--bar2"/>
                        <div className="header--hamburger--bar3"/>
                    </div>
                    <div className="header--mobileNav--list header--mobileNav--list__invisible">
                        <ul className="header--mobileNav--ul">
                            {navData.map((x) => (
                                <li key={x.name} className="header--mobileNav--li">
                                    <Link
                                        to={x.to}
                                        className="header--mobileNav--link"
                                        onClick={(evt) => showMobileMenu(evt)}
                                    >
                                        {x.name.toUpperCase()}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
