import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Helmet from "react-helmet";

import Header from "./header"
import Footer from "./footer";
import StyledBackgroundSection from "./defaultBackground";

import favicon from "../images/favicon.ico";
import appleTouchIcon from "../images/apple-touch-icon.png";
import favicon16 from "../images/favicon-16x16.png";
import favicon32 from "../images/favicon-32x32.png";
import logo3000 from "../images/logo_round.png";

import "../styles/reset.styl"
import "../styles/app.styl"

const Layout = ({children, headerModifier}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <StyledBackgroundSection className="background">
            <Helmet>
                <link rel="icon" href={favicon} />
                <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
                <link rel="icon" type="image/png" sizes="3000x3000" href={logo3000} />
            </Helmet>
            <Header modifier={headerModifier} siteTitle={data.site.siteMetadata?.title || `Junge Philharmonie Berlin`}/>
            <main>{children}</main>
            <Footer/>
        </StyledBackgroundSection>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
