import {Link} from "gatsby"
import React from "react"

const Footer = () => (
    <footer className="footer">
        <div className="footer--content">
            © {new Date().getFullYear()} Junge Philharmonie Berlin e.V. ・ <Link to="/impressum">Impressum</Link>
        </div>
        <div className="fb-page"
             data-href="https://www.facebook.com/JungePhilharmonieBerlin">
        </div>
    </footer>
)

export default Footer