import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const BackgroundSection = ({className, children}) => {
    const data = useStaticQuery(graphql`
        query bgQuery {
          file(relativePath: {eq: "mozart_berliner_dom_totale.jpg"}) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
    `);

    return (
        <>
            <BackgroundImage
                Tag="section"
                className={className}
                fluid={data.file.childImageSharp.fluid}
                backgroundColor={`#040e18`}
            >
            </BackgroundImage>
            {children}
        </>
    );
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  position: fixed !important;
  z-index: -100;
`

export default StyledBackgroundSection;